import React from "react"
import "../../styles/styles.scss"
import Settings from "../../settings.js"

const Form = () => {
    return (
        <div className="form">
            <span className="form__label">
                <span className="desktop">
                    You earned $100 to pay-off any trading<br/>
                    commissions on Margex
                </span>
                <span className="mobile">
                    You earned $100 to pay-off any trading commissions on Margex
                </span>
            </span>
            <form
                className="form__controls"
                action={Settings.emailFormActionURL}
                id="email-form"
            >
                <input type="text" className="form__controls__input" placeholder="Enter your email"/>
                <div className="form__controls__button">Claim Prize</div>
            </form>
            <div className="form__message -success">
                <div className="form__message__title">Success!</div>
                <div className="form__message__text">You will shortly receive an email with your prize.</div>
            </div>
            <div className="form__message -error">
                <div className="form__message__title">Sending error</div>
                <div className="form__message__text">Sorry, please try again later.</div>
            </div>
        </div>
    )
}

export default Form
