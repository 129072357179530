import * as React from "react"
import Helmet from "react-helmet"
import { navigate } from 'gatsby'
import Layout from "../components/layout/layout"
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from "react-parallax-mouse"
import { Scrollbar } from "react-scrollbars-custom"
import Form from "../components/form/form"
import Stars from "../components/stars/stars"
import Settings from "../settings.js"

const ResultsPage = () => {
  // Get quiz data
  let data = Settings.BillAckman;
  let totalBalance, betterThan, userId, totalInBTC, bitcoinRate;
  if (typeof window !== 'undefined') {
    if (
      !localStorage.getItem('quiz-progress') ||
      localStorage.getItem('quiz-progress') !== '/results'
    ) {
        navigate('/');
    } else {
      if (
        localStorage.getItem('quiz-current-balance') !== 'null' &&
        localStorage.getItem('quiz-question-1-data') !== 'null' &&
        localStorage.getItem('quiz-question-2-data') !== 'null' &&
        localStorage.getItem('quiz-question-3-data') !== 'null' &&
        localStorage.getItem('quiz-question-4-data') !== 'null' &&
        localStorage.getItem('quiz-question-5-data') !== 'null'
      ) {
        totalBalance = parseFloat(localStorage.getItem('quiz-current-balance'));

        bitcoinRate = (parseFloat(localStorage.getItem('quiz-bitcoin-rate')) > 0) ? parseFloat(localStorage.getItem('quiz-bitcoin-rate')) : 20000;

        totalInBTC = ((totalBalance / bitcoinRate).toFixed(2)).toLocaleString('en');

        betterThan = Math.floor(totalBalance * Settings.betterThanFactor);

        const summaryData = [
          JSON.parse(localStorage.getItem('quiz-question-1-data')),
          JSON.parse(localStorage.getItem('quiz-question-2-data')),
          JSON.parse(localStorage.getItem('quiz-question-3-data')),
          JSON.parse(localStorage.getItem('quiz-question-4-data')),
          JSON.parse(localStorage.getItem('quiz-question-5-data'))
        ];

        let correctAnswers = 0;
        let leverageSumm = 0;
        let sellOperations = 0;
        for (let i in summaryData) {
          // Number of correct answers
          if (summaryData[i].correctAnswer === true) correctAnswers++;

          // Numbers of Buy & Sell operations
          if (summaryData[i].type === 'Sell') sellOperations++;

          // Leverages arithmetic average
          if (summaryData[i].correctAnswer === true)
            leverageSumm += parseFloat(summaryData[i].leverage);
        }
        let averageLeverage = leverageSumm / correctAnswers;

        // console.log(summaryData);

        // console.log(
        //   'correctAnswers: ' + correctAnswers + ' | ' +
        //   'average leverage: ' + averageLeverage + ' | ' +
        //   'sell operations: ' + sellOperations
        // );

        if (
          correctAnswers === 3 &&
          averageLeverage > 70 &&
          sellOperations !== 2
        ) data = Settings.RayDalio;

        else if (
          sellOperations === 2
        ) data = Settings.DavidEinhorn;

        else if (
          correctAnswers === 3 &&
          averageLeverage <= 20
        ) data = Settings.WarrenBuffet;

        else if (
          correctAnswers === 2
        ) data = Settings.EdwardThorp;

        else if (
          correctAnswers === 3 &&
          averageLeverage >= 40 &&
          averageLeverage <= 70
        ) data = Settings.JohnPaulson;

        else data = Settings.BillAckman;

        userId = betterThan * 1.3;
        localStorage.setItem('quiz-user-id', userId);

        return (
          <Layout>
            <Helmet>
              <body className="-blue results" />
            </Helmet>
            <MouseParallaxContainer
              enabled={Settings.parallaxOn}
              className="screen results"
            >
      
              <div className="results__header">
                <p className="results__header__paragraph">
                  <span
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="0.5"
                    data-animation="translateY"
                  >Your final balance&nbsp;</span>
                  <span
                    className="results__header__paragraph__accent"
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="1"
                    data-count-type="count"
                    data-count-delay="1.5"
                    data-count-finish={totalBalance}
                  ><i>$</i><span>{totalBalance.toLocaleString('en')}</span></span>
                  <span
                    className="results__header__paragraph__accent"
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="3"
                    data-animation="scaleDown"
                  >&nbsp;({totalInBTC} BTC)</span>
                </p>
                <p className="results__header__paragraph">
                  <span
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="4"
                    data-animation="scaleDown"
                  >You did better than&nbsp;</span>
                  <span
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="4"
                    data-count-type="count"
                    data-count-delay="4.5"
                    data-count-step="1"
                    data-count-finish={betterThan}
                  ><span>{betterThan.toLocaleString('en')}</span></span>
                  <span
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="5.5"
                    data-animation="scaleDown"
                  >&nbsp;other quiz participants!</span>
                </p>
              </div>
      
              <div className="results__main">
                <div
                  className={"results__main__image " + data.imageClass}
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="8"
                  data-animation="scaleUp"
                ></div>
                <div className="results__main__content">
                  <div
                    className="results__main__content__text"
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="6"
                    data-animation="translateY"
                  >
                    <div className="results__main__content__text__inner">
                      <span
                        data-type="animated-show"
                        data-duration="0.5"
                        data-delay="6.5"
                        data-animation="translateY"
                      >
                        <Stars/>
                      </span>
                      <div
                        className="results__main__content__text__scroll"
                        data-type="animated-show"
                        data-duration="0.5"
                        data-delay="7"
                        data-animation="translateY"
                      >
                        <Scrollbar>
                          <h3>Your trading style: {data.personName}</h3>
                          <p>{data.text}</p>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>
                  <div
                    className="results__main__content__form"
                    data-type="animated-show"
                    data-duration="0.5"
                    data-delay="8"
                    data-animation="translateY"
                  >
                    <Form></Form>
                  </div>
                </div>
              </div>
      
              <div
                className="results__description"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="8.5"
                data-animation="translateY"
              >
                <span className="desktop">
                  Margex is a reliable and innovative way to trade crypto with up to 100x leverage.<br />
                  Margex helps to leverage your trading skills and maximize profits.
                </span>
                <span className="mobile">
                  Margex is a reliable and innovative way to trade crypto with up to 100x leverage. Margex helps to leverage your trading skills and maximize profits.
                </span>
              </div>
      
              <div
                className="results__social"
                data-type="animated-show"
                data-duration="0.5"
                data-delay="9"
                data-animation="translateY"
              >
                <a
                  href={Settings.twitterURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="results__social__item -twitter"
                >Twitter</a>
                <a
                  href={Settings.telegramURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="results__social__item -telegram"
                >Telegram</a>
                <a
                  href={Settings.facebookURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="results__social__item -facebook"
                >Facebook</a>
              </div>
      
              <MouseParallaxChild
                className="screen__person"
                factorX={0.04}
                factorY={0.04}
              >
                <div
                  className={data.imageClass}
                  data-type="animated-show"
                  data-duration="0.1"
                  data-delay="0.05"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__dots"
                factorX={0.12}
                factorY={0.12}
              >
                <div
                  className="-left"
                  data-type="animated-show"
                  data-duration="0.1"
                  data-delay="0.15"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__dots"
                factorX={0.4}
                factorY={0.4}
              >
                <div
                  className="-right"
                  data-type="animated-show"
                  data-duration="0.1"
                  data-delay="0.55"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__chart"
                factorX={0.16}
                factorY={0.16}
              >
                <div
                  className="-green -down"
                  data-type="animated-show"
                  data-animation="scaleDown"
                  data-duration="0.1"
                  data-delay="0.2"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__chart"
                factorX={0.48}
                factorY={0.48}
              >
                <div
                  className="-green -up"
                  data-type="animated-show"
                  data-animation="scaleUp"
                  data-duration="0.1"
                  data-delay="0.6"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__bitcoin"
                factorX={0.24}
                factorY={0.24}
              >
                <div
                  className="-bottom"
                  data-type="animated-show"
                  data-animation="scaleUp"
                  data-duration="0.1"
                  data-delay="0.3"
                />
              </MouseParallaxChild>
              <MouseParallaxChild
                className="screen__confetti"
                factorX={0.05}
                factorY={0.05}
              >
                <div
                  className="js-container container"
                  data-type="animated-show"
                  data-animation="scaleUp"
                  data-duration="1"
                  data-delay="2"
                />
              </MouseParallaxChild>
            </MouseParallaxContainer>
          </Layout>
        )
      }
    }
  }
}

export default ResultsPage
